import React from "react";
import theme from "theme";
import { Theme, Link, Strong, Text, Image, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Asvata Olare Bar
			</title>
			<meta name={"description"} content={"Welcome to Asvata Olare Bar in Delhi – your top-notch entertainment destination! It's not just a bar"} />
			<meta property={"og:title"} content={"Home | Asvata Olare Bar"} />
			<meta property={"og:description"} content={"Welcome to Asvata Olare Bar in Delhi – your top-notch entertainment destination! It's not just a bar"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/OGimage.png?v=2021-09-23T20:32:10.363Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/favicon.png?v=2021-09-23T20:33:18.314Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/270.png?v=2021-09-23T20:33:32.819Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header>
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link" />
		</Components.Header>
		<Section padding="60px 0 20px 0" quarkly-title="HeroBlock">
			<Text
				color="--dark"
				lg-font="normal 800 40px/1.2 &quot;Manrope&quot;, sans-serif"
				md-font="normal 800 28px/1.2 &quot;Manrope&quot;, sans-serif"
				margin="0px 0px 0px 0px"
				font="--headline1"
			>
				<Strong>
					Welcome to Asvata Olare Bar{" "}
				</Strong>
			</Text>
			<Image
				max-height="600px"
				object-position="0% 80%"
				lg-max-height="300px"
				md-max-height="200px"
				sm-max-height="150px"
				src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12:45:33.603Z"
				width="100%"
				object-fit="cover"
				margin="32px 0px 0px 0px"
				srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
		</Section>
		<Section padding="28px 0 74px 0" md-padding="28px 0 28px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="40%"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					lg-font="normal 700 28px/1.2 &quot;Manrope&quot;, sans-serif"
					sm-font="normal 700 22px/1.2 &quot;Manrope&quot;, sans-serif"
				>
					<Strong>
						Join Now for the Ultimate Sports Experience
					</Strong>
				</Text>
			</Box>
			<Box
				md-width="100%"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 10%"
				width="60%"
				display="flex"
				md-padding="0px 0px 0px 0px"
				md-margin="32px 0px 0px 0px"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					lg-font="normal 400 16px/1.5 &quot;Manrope&quot;, sans-serif"
					sm-font="normal 400 14px/1.5 &quot;Manrope&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Manrope&quot;, sans-serif"
				>
					Join us now and discover the fantastic opportunities we offer for having a great time. As a token of our appreciation, we have a special "Welcome Bonus" waiting for you. It's the best way to have fun while enjoying your favorite sports and creating unforgettable memories with friends.
				</Text>
			</Box>
		</Section>
		<Section padding="54px 0 74px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="0px 16px 0px 0px"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Text
					md-font="normal 600 18px/1.2 &quot;Manrope&quot;, sans-serif"
					md-margin="0px 0px 24px 0px"
					margin="0px 0px 48px 0px"
					font="--headline3"
					color="--dark"
					lg-font="normal 600 24px/1.2 &quot;Manrope&quot;, sans-serif"
				>
					<Strong>
						Unparalleled Entertainment
					</Strong>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					lg-font="normal 400 16px/1.5 &quot;Manrope&quot;, sans-serif"
					sm-font="normal 400 14px/1.5 &quot;Manrope&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Manrope&quot;, sans-serif"
				>
					At Asvata Olare Bar, we're committed to delivering unparalleled entertainment. Our vibrant atmosphere, dedicated staff, and top-of-the-line sports-watching facilities ensure that every visit is filled with excitement. Whether you're here to cheer for your team or simply unwind, we've got you covered.
					<br />
					<br />
				</Text>
			</Box>
			<Box
				md-width="50%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				padding="0px 16px 0px 0px"
			>
				<Image
					width="100%"
					max-width="400px"
					lg-min-height="300px"
					sm-min-height="188px"
					src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12:45:33.636Z"
					min-height="600px"
					object-fit="cover"
					object-position="40% 0%"
					srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.636Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.636Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.636Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.636Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.636Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.636Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.636Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="16px 0px 16px 0px"
					font="--headline4"
					lg-font="600 18PX/1.3 &quot;Manrope&quot;, sans-serif"
					md-font="600 16px/1.3 &quot;Manrope&quot;, sans-serif"
					md-margin="8px 0px 0px 0px"
				>
					Beer
				</Text>
			</Box>
			<Box
				width="70%"
				padding="0px 0px 0px 16px"
				md-width="50%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Image
					object-fit="cover"
					width="100%"
					lg-min-height="390px"
					sm-min-height="221px"
					src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12:47:34.698Z"
					min-height="778px"
					srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12%3A47%3A34.698Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12%3A47%3A34.698Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12%3A47%3A34.698Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12%3A47%3A34.698Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12%3A47%3A34.698Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12%3A47%3A34.698Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/sports-bar-min.jpg?v=2023-10-03T12%3A47%3A34.698Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="16px 0px 16px 0px"
					font="--headline4"
					lg-font="600 18PX/1.3 &quot;Manrope&quot;, sans-serif"
					md-font="600 16px/1.3 &quot;Manrope&quot;, sans-serif"
					md-margin="8px 0px 0px 0px"
				>
					Football
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="32px 16px 0px 0px"
				width="33.333%"
				md-width="50%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-height="544px"
					object-fit="cover"
					object-position="40% 0%"
					width="100%"
					max-width="400px"
					lg-min-height="272px"
					sm-min-height="221px"
					src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12:45:33.621Z"
					srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.621Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.621Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.621Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.621Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.621Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.621Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.621Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					md-margin="8px 0px 0px 0px"
					margin="16px 0px 0px 0px"
					font="--headline4"
					lg-font="600 18PX/1.3 &quot;Manrope&quot;, sans-serif"
					md-font="600 16px/1.3 &quot;Manrope&quot;, sans-serif"
				>
					Snacks
				</Text>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="33.333%"
				empty-min-width="64px"
				padding="32px 16px 0px 16px"
				md-width="50%"
				md-padding="32px 0px 0px 16px"
			>
				<Image
					lg-min-height="177px"
					src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12:45:33.583Z"
					min-height="353px"
					object-fit="cover"
					object-position="40% 0%"
					width="100%"
					max-width="400px"
					srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.583Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.583Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.583Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.583Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.583Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.583Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/best-pocket-friendly%20%281%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.583Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					width="100%"
					lg-font="600 18PX/1.3 &quot;Manrope&quot;, sans-serif"
					md-font="600 16px/1.3 &quot;Manrope&quot;, sans-serif"
					md-margin="8px 0px 0px 0px"
					margin="16px 0px 0px 0px"
					font="--headline4"
				>
					Friends
				</Text>
			</Box>
			<Box
				width="33.333%"
				padding="32px 0px 0px 16px"
				md-padding="32px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-border-style="solid"
			>
				<Image
					min-height="450px"
					object-fit="cover"
					object-position="40% 0%"
					src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12:45:33.594Z"
					width="100%"
					max-width="400px"
					lg-min-height="225px"
					md-max-width="none"
					md-max-height="325px"
					sm-max-height="188px"
					srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.594Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.594Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.594Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.594Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.594Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.594Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/barshala%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.594Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					md-margin="8px 0px 0px 0px"
					margin="16px 0px 0px 0px"
					font="--headline4"
					lg-font="600 18PX/1.3 &quot;Manrope&quot;, sans-serif"
					md-font="600 16px/1.3 &quot;Manrope&quot;, sans-serif"
				>
					Atmosphere
				</Text>
			</Box>
		</Section>
		<Box
			min-width="100px"
			min-height="100px"
			margin="0px auto 0px auto"
			position="static"
			text-align="center"
		>
			<Link
				href="/contact-us"
				color="#000000"
				padding="8px 16px 8px 16px"
				text-decoration-line="initial"
				font="24px sans-serif"
				background="rgba(168, 168, 154, 0.81)"
				display="inline-block"
				align-self="flex-start"
				margin="30px auto 20px auto"
				border-radius="8px"
				text-align="center"
			>
				Contact us
			</Link>
		</Box>
		<Components.Footer>
			<Override slot="box2" border-width={0} />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});